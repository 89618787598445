/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./styles/animate.css";
import "./styles/aos.css";
import "./styles/app.css";
import "./styles/bootstrap.min.css";
import "./styles/magnific-popup.css";
import "./styles/owl.theme.default.min.css";
import "./styles/splitting.css";
import "./styles/style.css";
import "./styles/fontawesome-all.css";
import "./styles/icomoon.css";
import "./styles/swiper.css";
import "./styles/themify-icons.css";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import Index from "./components/Index";
import VisiMisi from "./pages/VisiMisi";
import Prosedur from "./pages/praktikum/Prosedur";
import KegiatanAsisten from "./pages/praktikum/KegiatanAsisten";
import Asisten from "./pages/laboratorium/Asisten";
import SaranaDanPrasarana from "./pages/laboratorium/SaranaDanPrasarana";
import TentangKami from "./pages/TentangKami";
import Kontak from "./pages/Kontak";
import Footer from "./components/Footer";
import SaranaIot from "./pages/laboratorium/Iot";
import SaranaStartUp from "./pages/laboratorium/Startup";
import SaranaMulmed from "./pages/laboratorium/Mulmed";
import SaranaComnet from "./pages/laboratorium/Comnet";
import SaranaDs from "./pages/laboratorium/Ds";
import SaranaCv from "./pages/laboratorium/Cv";
import SaranaMicro from "./pages/laboratorium/Micro";
import SaranaRiset2 from "./pages/laboratorium/Rs2";
import SaranaRiset1 from "./pages/laboratorium/Rs1";
import SaranaRiset3 from "./pages/laboratorium/Rs3";
import Sop from "./pages/laboratorium/Sop";

import AOS from "aos";
import "aos/dist/aos.css";

function Utama() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
    AOS.refresh();
  }, []);
  return (
    <React.Fragment>
      <div className="body_wrap">
        <div id="thetop"></div>
        <div id="backtotop">
          <a href="#" id="scroll">
            <i className="fal fa-arrow-up"></i>
            <i className="fal fa-arrow-up"></i>
          </a>
        </div>
        <Header></Header>
        <Sidebar></Sidebar>
        <Switch>
          <Route exact path="/" component={Index} />
          <Route exact path="/tamu/visi-dan-misi" component={VisiMisi} />
          <Route exact path="/tamu/praktikum/prosedur" component={Prosedur} />
          <Route
            exact
            path="/tamu/praktikum/kegiatan-asistensi"
            component={KegiatanAsisten}
          />
          <Route exact path="/tamu/laboratorium/asisten" component={Asisten} />
          <Route exact path="/tamu/laboratorium/sop" component={Sop} />
          <Route
            exact
            path="/tamu/laboratorium/sarana-dan-prasarana"
            component={SaranaDanPrasarana}
          />
          <Route exact path="/tamu/laboratorium/Iot" component={SaranaIot} />
          <Route
            exact
            path="/tamu/laboratorium/Startup"
            component={SaranaStartUp}
          />
          <Route
            exact
            path="/tamu/laboratorium/Mulmed"
            component={SaranaMulmed}
          />
          <Route
            exact
            path="/tamu/laboratorium/Comnet"
            component={SaranaComnet}
          />
          <Route exact path="/tamu/laboratorium/Ds" component={SaranaDs} />
          <Route exact path="/tamu/laboratorium/Cv" component={SaranaCv} />
          <Route
            exact
            path="/tamu/laboratorium/Micro"
            component={SaranaMicro}
          />
          <Route
            exact
            path="/tamu/laboratorium/Riset2"
            component={SaranaRiset2}
          />
          <Route
            exact
            path="/tamu/laboratorium/Riset1"
            component={SaranaRiset1}
          />
          <Route
            exact
            path="/tamu/laboratorium/Riset3"
            component={SaranaRiset3}
          />
          <Route exact path="/tamu/tentang-kami" component={TentangKami} />
          <Route exact path="/tamu/kontak" component={Kontak} />
        </Switch>
        <Footer></Footer>
      </div>
    </React.Fragment>
  );
}

ReactDOM.render(
  <BrowserRouter>
    <Utama />
  </BrowserRouter>,
  document.getElementById("root")
);
